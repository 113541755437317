import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrimaryBoxInfo from 'components/Advanced_User/All_BlueIris_Tutorials/PrimaryBox';
import NavButtons from 'components/Software/Windows/Blue_Iris_v5/NavButtons';
import ForumBox from 'components/Software/Windows/Blue_Iris/ForumBox';
import TimeLine from 'components/Products/CommentList/MqttIFTTTList';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "BlueIris MQTT with Node-RED",
  "path": "/Software/Windows/Blue_Iris_v5/INSTAR_MQTT/",
  "dateChanged": "2020-06-12",
  "author": "Mike Polinowski",
  "excerpt": "The Windows app Blue Iris for your PC or Laptop. Control your camera from your computer and harness the computing power for advanced features. Use the INSTAR MQTT Service to connect BlueIris with your INSTAR Camera and Node-RED.",
  "image": "./P_SearchThumb_Blue_Iris.png",
  "social": "/images/Search/P_SearchThumb_Blue_Iris.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Windows-Blue-Iris_white.webp",
  "chapter": "Software",
  "category": "smarthome",
  "type": "Node-RED"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='Blue Iris v5' dateChanged='2020-06-10' author='Mike Polinowski' tag='INSTAR IP Camera' description='The Windows app Blue Iris for your PC or Laptop. Control your camera from your computer and harness the computing power for advanced features.' image='/images/Search/P_SearchThumb_Blue_Iris.png' twitter='/images/Search/P_SearchThumb_Blue_Iris.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Software/Windows/Blue_Iris_v5/INSTAR_MQTT/' locationFR='/fr/Software/Windows/Blue_Iris_v5/INSTAR_MQTT/' crumbLabel="INSTAR MQTT" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <PrimaryBoxInfo mdxType="PrimaryBoxInfo" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h1 {...{
      "id": "blueiris-mqtt-with-node-red",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#blueiris-mqtt-with-node-red",
        "aria-label": "blueiris mqtt with node red permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`BlueIris MQTT with Node-RED`}</h1>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#konfigurieren-des-blueiris-mqtt-dienstes"
        }}>{`Konfigurieren des BlueIris-MQTT-Dienstes`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#steuerung-von-blueiris-durch-mqtt"
        }}>{`Steuerung von BlueIris durch MQTT`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#admin-befehle"
            }}>{`Admin Befehle`}</a></li>
        </ul>
      </li>
    </ul>
    <EuiSpacer mdxType="EuiSpacer" />
    <TimeLine mdxType="TimeLine" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "configuring-the-blueiris-mqtt-service",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#configuring-the-blueiris-mqtt-service",
        "aria-label": "configuring the blueiris mqtt service permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Configuring the BlueIris MQTT Service`}</h2>
    <p>{`To activate the MQTT Service enter the BlueIris `}<strong parentName="p">{`Software Settings`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "898px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4e6745a53e3132c9721eab081ec4c54b/84cc5/BlueIris5_INSTAR_MQTT_00.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "10.434782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAACCAIAAADXZGvcAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAhUlEQVQI1wF6AIX/AElWWVFkeT5DUY1teoxzho50iJuGmq6Uo5GapZifrXSRg2CJcW+Xf2l7fiY3PWNsepGaqJKcqZagrkRNWQB2hYqVn62UnKq7vcPEw8fExsre3+SorLMwQVUzRVkwQlktQForPVgqPlYvQ1sqQFcoPVQnPFMnPFMzRlsNJjbnA7JJvgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4e6745a53e3132c9721eab081ec4c54b/e4706/BlueIris5_INSTAR_MQTT_00.avif 230w", "/en/static/4e6745a53e3132c9721eab081ec4c54b/d1af7/BlueIris5_INSTAR_MQTT_00.avif 460w", "/en/static/4e6745a53e3132c9721eab081ec4c54b/f430a/BlueIris5_INSTAR_MQTT_00.avif 898w"],
              "sizes": "(max-width: 898px) 100vw, 898px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4e6745a53e3132c9721eab081ec4c54b/a0b58/BlueIris5_INSTAR_MQTT_00.webp 230w", "/en/static/4e6745a53e3132c9721eab081ec4c54b/bc10c/BlueIris5_INSTAR_MQTT_00.webp 460w", "/en/static/4e6745a53e3132c9721eab081ec4c54b/005c4/BlueIris5_INSTAR_MQTT_00.webp 898w"],
              "sizes": "(max-width: 898px) 100vw, 898px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4e6745a53e3132c9721eab081ec4c54b/81c8e/BlueIris5_INSTAR_MQTT_00.png 230w", "/en/static/4e6745a53e3132c9721eab081ec4c54b/08a84/BlueIris5_INSTAR_MQTT_00.png 460w", "/en/static/4e6745a53e3132c9721eab081ec4c54b/84cc5/BlueIris5_INSTAR_MQTT_00.png 898w"],
              "sizes": "(max-width: 898px) 100vw, 898px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4e6745a53e3132c9721eab081ec4c54b/84cc5/BlueIris5_INSTAR_MQTT_00.png",
              "alt": "Blue Iris for your INSTAR IP Camera",
              "title": "Blue Iris for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Switch to the `}<strong parentName="p">{`Digital IO and IoT`}</strong>{` tab and click to `}<strong parentName="p">{`Configure`}</strong>{` the MQTT service:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9c53d38acaccffac199ee33fd1080035/0a867/BlueIris5_INSTAR_MQTT_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "26.956521739130434%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABKklEQVQY003N20oCQRgA4H9Zwd2yeriusgNpZwsvguoi6CV6hG5roRTSMgMjxTIlDfPCwnVcm7EdR3dHc3VsgyDoe4EP/KFw+Oh4bm1/MXS4EjoIbOydaImrVCGeyp9e3kducheJRy2eiSafzq+zWiwTvc1rsfRZLBNJ5mDWvxRc35kPbPqXtxaCm6vbu6+VSvuTWF1mIB3V3hkleu2N0daHUcdNRFsYG8io17qMgtfrhV+SJAGAqk7k8gXOuc17Qohevz9wHNbpMtaxLdtssy9n6IxEbzCwLBsUVQWQZFmWPR4AmJqeKZZeOOeYYISQXtcxIeVyuVqtCtcVrEOzD6279BA1hOuCoij/50mf77lYEmJkmmbzDyGkTakYj78dh2NsNQxh86EY/QBWash2+apHrgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9c53d38acaccffac199ee33fd1080035/e4706/BlueIris5_INSTAR_MQTT_01.avif 230w", "/en/static/9c53d38acaccffac199ee33fd1080035/d1af7/BlueIris5_INSTAR_MQTT_01.avif 460w", "/en/static/9c53d38acaccffac199ee33fd1080035/7f308/BlueIris5_INSTAR_MQTT_01.avif 920w", "/en/static/9c53d38acaccffac199ee33fd1080035/c0669/BlueIris5_INSTAR_MQTT_01.avif 986w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9c53d38acaccffac199ee33fd1080035/a0b58/BlueIris5_INSTAR_MQTT_01.webp 230w", "/en/static/9c53d38acaccffac199ee33fd1080035/bc10c/BlueIris5_INSTAR_MQTT_01.webp 460w", "/en/static/9c53d38acaccffac199ee33fd1080035/966d8/BlueIris5_INSTAR_MQTT_01.webp 920w", "/en/static/9c53d38acaccffac199ee33fd1080035/b508b/BlueIris5_INSTAR_MQTT_01.webp 986w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9c53d38acaccffac199ee33fd1080035/81c8e/BlueIris5_INSTAR_MQTT_01.png 230w", "/en/static/9c53d38acaccffac199ee33fd1080035/08a84/BlueIris5_INSTAR_MQTT_01.png 460w", "/en/static/9c53d38acaccffac199ee33fd1080035/c0255/BlueIris5_INSTAR_MQTT_01.png 920w", "/en/static/9c53d38acaccffac199ee33fd1080035/0a867/BlueIris5_INSTAR_MQTT_01.png 986w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9c53d38acaccffac199ee33fd1080035/c0255/BlueIris5_INSTAR_MQTT_01.png",
              "alt": "Blue Iris for your INSTAR IP Camera",
              "title": "Blue Iris for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`In this case I am using an `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Network/MQTT/"
      }}>{`INSTAR MQTT Camera`}</a>{` as my MQTT broker. Type in your broker IP address (e.g. IP address of your camera), the broker login and port:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/22a87b0d3d526915664f992e0b6180ee/d56b5/BlueIris5_INSTAR_MQTT_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "48.69565217391305%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB3ElEQVQoz32Qy27TQBiF/WIg0caJ754ZTxLb1IEGKoG4iw0LNlW7aJvEUaFdUQk1cTdILNj2FUCIN0iaoNiOM77ISuLcFBSbTVnw6Ww/nfP/1MXFp7OzjyfHx/Vardlsmmaj0WjUG+apefL26LJo/kT176D24588PP+FD6+pyWQcx/FwOIyiaDqdTiaT2Ww2TZL1ev3l6ze5vKtXnyP9MbqfRk+jVcvV15K2R/V6vU6n0+/3CCFhShRFruskSdJuXQKJrxi6Vi4qUEZAKmFUxAgjYFQqsixTvu//TvEJGY83K+I4HgwGSZJYV1eiKBqGUSqVFEWBEAqCQNO5DdtbPMdSQRDcdLvdm65t2+5wGASBn7JarSzLkiTJMAyMMUIIQsgwTKbSDM+LEmXbdr/fd13XdhzP88IwDIJgNBotFgvLshBCuq4DADJZEHiOZZh8rsCJvAQpx3GiKMpuzcwwDAkhy+XSsixRFFVVxSkAgLtb+Tv38rkCv53nWJbbzCaE+LfxPG8+n2fyzs6OqqoKxmUMD5+K+08k7f1n9eiaLz6g/tbeJmtutVocx2mahhACEJYxOnhT2X9lVHdfPtp7J8vK/+R2u03TdPYtBAHEqvDig/jMPOCk80JBZdk/NypvJuj7UPoAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/22a87b0d3d526915664f992e0b6180ee/e4706/BlueIris5_INSTAR_MQTT_02.avif 230w", "/en/static/22a87b0d3d526915664f992e0b6180ee/d1af7/BlueIris5_INSTAR_MQTT_02.avif 460w", "/en/static/22a87b0d3d526915664f992e0b6180ee/7f308/BlueIris5_INSTAR_MQTT_02.avif 920w", "/en/static/22a87b0d3d526915664f992e0b6180ee/eb15d/BlueIris5_INSTAR_MQTT_02.avif 1215w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/22a87b0d3d526915664f992e0b6180ee/a0b58/BlueIris5_INSTAR_MQTT_02.webp 230w", "/en/static/22a87b0d3d526915664f992e0b6180ee/bc10c/BlueIris5_INSTAR_MQTT_02.webp 460w", "/en/static/22a87b0d3d526915664f992e0b6180ee/966d8/BlueIris5_INSTAR_MQTT_02.webp 920w", "/en/static/22a87b0d3d526915664f992e0b6180ee/34ce3/BlueIris5_INSTAR_MQTT_02.webp 1215w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/22a87b0d3d526915664f992e0b6180ee/81c8e/BlueIris5_INSTAR_MQTT_02.png 230w", "/en/static/22a87b0d3d526915664f992e0b6180ee/08a84/BlueIris5_INSTAR_MQTT_02.png 460w", "/en/static/22a87b0d3d526915664f992e0b6180ee/c0255/BlueIris5_INSTAR_MQTT_02.png 920w", "/en/static/22a87b0d3d526915664f992e0b6180ee/d56b5/BlueIris5_INSTAR_MQTT_02.png 1215w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/22a87b0d3d526915664f992e0b6180ee/c0255/BlueIris5_INSTAR_MQTT_02.png",
              "alt": "Blue Iris for your INSTAR IP Camera",
              "title": "Blue Iris for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "mqtt-alarm-service",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#mqtt-alarm-service",
        "aria-label": "mqtt alarm service permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`MQTT Alarm Service`}</h2>
    <p>{`Now right-click your cameras live video and select `}<strong parentName="p">{`Camera Settings`}</strong>{` from the pop-up menu. In the settings menu switch to the `}<strong parentName="p">{`Alerts`}</strong>{` tab and click on `}<strong parentName="p">{`ON alert...`}</strong>{` in the `}<strong parentName="p">{`Actions`}</strong>{` section:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/67855c0ae7a15c1abe64255b80d4824b/eb390/BlueIris5_INSTAR_MQTT_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "76.52173913043477%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAC20lEQVQoz32RW0sUYRiAv1XWtIPRVRQURDcipdRVIJR5oELzkJVgaoagaSRprJaHi3KPltESWxZbBN31PzpC0NnSdA9zPu/MN9/OzM7MfrFad9Z7/7zPw/uCjv4r3VdnG1r7WrqGz3QONLd09wyOTgYezISfXPffO3dj5Pz06MXJmera1prmC+OzMd9sbHru6YmOgfaznSBwN/zwUSy6sBC6H51/EL0TjfaN3L4WfDF861n/VLx3It7ji/dMxC9NP++9Ge8af9zlWzg/FuudeuaPvQRDI76etrbOxuNVByv37tldusnr9wezSGdoyoBZPaMyFA2hKksCTRGKLNqWmVEkSeC+Lf4E9adOn6w91nD40IGDVRUVFeXl5bP+IM5jWqDer776kH6zwi5BDYqSCCEkSZIgCJIkM5lMKkWAvuGx9rr6xtqj1Uca9lfVlO/c5w+EMMYkQ7z7+vrt91dflj7RNMNxHMsUhmUZgiRN00yTFOgY9J09191U11TTevlI89CuyrpAIJjL5RiGEXhR5EWowYyq6lCHUEcIWZZlGEbedZMpApRt2V5cus27eXtJ2bayrTsA8ARCYYwxLwiyIouiIEmSLMuqqiqKIooix3Oqqv6BvV4v+DtFRUUAgEAo4rhOJqOYpmkYBkLINM18Pp/L5RzHwRi7rosxTqTSwFtSUuA8HuDxFBUXAwCCoYjjOAzD8ILA85ysyKZpuq6r64Vsx3GMtV0bmyN35jHGslJI1VTVMMx1m67rlmVhjNf9/4Dn7mKMOY6TJIkkqdXEqihJlmXpOoIQyrLM8zxC+v/M2SyyHUcUeI6hOY7NZrMYY9u2WZalKErTtEQy/U/Ysm1XR+qPFWE5iZaTSJRYnuN53rKsfB6vZW8Eh9eybcfJQV39lZBWUnA1hRRFVhRZkg3DsG27cO0NzcFQxHXdwm81DSI9i5Ckwa8JejHFkTSbTCRomiaI9MePn38D1Sk359+m9RYAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/67855c0ae7a15c1abe64255b80d4824b/e4706/BlueIris5_INSTAR_MQTT_03.avif 230w", "/en/static/67855c0ae7a15c1abe64255b80d4824b/d1af7/BlueIris5_INSTAR_MQTT_03.avif 460w", "/en/static/67855c0ae7a15c1abe64255b80d4824b/7f308/BlueIris5_INSTAR_MQTT_03.avif 920w", "/en/static/67855c0ae7a15c1abe64255b80d4824b/f3790/BlueIris5_INSTAR_MQTT_03.avif 935w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/67855c0ae7a15c1abe64255b80d4824b/a0b58/BlueIris5_INSTAR_MQTT_03.webp 230w", "/en/static/67855c0ae7a15c1abe64255b80d4824b/bc10c/BlueIris5_INSTAR_MQTT_03.webp 460w", "/en/static/67855c0ae7a15c1abe64255b80d4824b/966d8/BlueIris5_INSTAR_MQTT_03.webp 920w", "/en/static/67855c0ae7a15c1abe64255b80d4824b/c7dd1/BlueIris5_INSTAR_MQTT_03.webp 935w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/67855c0ae7a15c1abe64255b80d4824b/81c8e/BlueIris5_INSTAR_MQTT_03.png 230w", "/en/static/67855c0ae7a15c1abe64255b80d4824b/08a84/BlueIris5_INSTAR_MQTT_03.png 460w", "/en/static/67855c0ae7a15c1abe64255b80d4824b/c0255/BlueIris5_INSTAR_MQTT_03.png 920w", "/en/static/67855c0ae7a15c1abe64255b80d4824b/eb390/BlueIris5_INSTAR_MQTT_03.png 935w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/67855c0ae7a15c1abe64255b80d4824b/c0255/BlueIris5_INSTAR_MQTT_03.png",
              "alt": "Blue Iris for your INSTAR IP Camera",
              "title": "Blue Iris for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Here we can define the action that we want to be triggered if the camera enters the alarm state. Choose `}<strong parentName="p">{`Web request or MQTT`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/90ba354eb40ab29a2c62f80ea5b54230/e548f/BlueIris5_INSTAR_MQTT_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.65217391304347%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACgklEQVQoz2MIz8hPLunyicgKSyqKTSoIiUxLL6ipbp1c0z4jqarKvzg7pq7CMzpD09ozKb+psH5iRduMsNRyd5/grMxshvqWxu7ujvae7prGhqb25sz8wsz88oa2/vK69rKq9qKy1sLSlrKajrLajvyyhoLyxpySuvzyxu7+yT6h4QzpOUVJQQHhjrZamupCAryWjt7z1uxbtOHQnFV7F6w/NGf13rlr9y/ccHjWyj2zV+5ZuP7Q3NX7Zq3cPWP5nviCBgYHVy8HSwsLfT1tHV0REbH+CVNev3n7+MnTd+8/vHn77vmLly9fvX7x8tWLl6/fvf8ARh/fvf/w5cvXh4+eMqSXNcT6+/t6eFq6R0pq2PZPX/j///9vX7/++///37+/3759/fnjx88fP75///bv338I+Adm3b17n8HcPcTa3tPczNnAKVxaz23SrOX/////8/fvf9wAovne/QcMrCwsDGDAAmY0N7f+////9+/fyOr+/YMy0DWzs7MzMjAwMTKysLKBNLe0gWz+84c4m1lZUWxuaaVEcxvE2f9wg7/gEKHQ5ocIzczMzAwMDA1Nzf/////w4cMnMPj86fPnz58/ffr89cuXr1++fPn85QuI8fX///+3bt9B19zS2vH569enz569efvm9ds3z1++fPbs2ZNnzy/eenDm2r079x7cuHHj4cNHjx8/vnHzNkIzExMTAwODm7vHlfMXb12+dv/WnTfPX7599eb7j18fPn15+OT505dvHzx6+vTZ8xevXj96/LS9sxuhmZGRkYGBQZCLa+3UBXvXHdi3bv+pPRd2L9+0cMG8ubNmzZ0ze8miRbNnz16wYMG0adMmTJigqqoKAAgnRlMaRUGHAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/90ba354eb40ab29a2c62f80ea5b54230/e4706/BlueIris5_INSTAR_MQTT_04.avif 230w", "/en/static/90ba354eb40ab29a2c62f80ea5b54230/d1af7/BlueIris5_INSTAR_MQTT_04.avif 460w", "/en/static/90ba354eb40ab29a2c62f80ea5b54230/7f308/BlueIris5_INSTAR_MQTT_04.avif 920w", "/en/static/90ba354eb40ab29a2c62f80ea5b54230/6d24a/BlueIris5_INSTAR_MQTT_04.avif 975w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/90ba354eb40ab29a2c62f80ea5b54230/a0b58/BlueIris5_INSTAR_MQTT_04.webp 230w", "/en/static/90ba354eb40ab29a2c62f80ea5b54230/bc10c/BlueIris5_INSTAR_MQTT_04.webp 460w", "/en/static/90ba354eb40ab29a2c62f80ea5b54230/966d8/BlueIris5_INSTAR_MQTT_04.webp 920w", "/en/static/90ba354eb40ab29a2c62f80ea5b54230/1e975/BlueIris5_INSTAR_MQTT_04.webp 975w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/90ba354eb40ab29a2c62f80ea5b54230/81c8e/BlueIris5_INSTAR_MQTT_04.png 230w", "/en/static/90ba354eb40ab29a2c62f80ea5b54230/08a84/BlueIris5_INSTAR_MQTT_04.png 460w", "/en/static/90ba354eb40ab29a2c62f80ea5b54230/c0255/BlueIris5_INSTAR_MQTT_04.png 920w", "/en/static/90ba354eb40ab29a2c62f80ea5b54230/e548f/BlueIris5_INSTAR_MQTT_04.png 975w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/90ba354eb40ab29a2c62f80ea5b54230/c0255/BlueIris5_INSTAR_MQTT_04.png",
              "alt": "Blue Iris for your INSTAR IP Camera",
              "title": "Blue Iris for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Choose an MQTT Topic - you can name it freely according to your MQTT setup. As payload choose `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`1`}</code>{` or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ON`}</code>{` to signify that the alarm state of this camera was activated:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4278fbfa2ae8645b4a6cc33db4eeb9ed/798d4/BlueIris5_INSTAR_MQTT_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "73.04347826086956%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7DAAAOwwHHb6hkAAADHElEQVQoz12PXUwTWRiGT4pITNYbE/bSRL1UMYbdRE1UJCLrv8KukixVFBqBiOhGFArUaKT1b10WyhaL/4Zr4wVgCP6AIgaRlgJZykxnpu2ZOecMc2amldZWLjADous+efOdc3GevN8B+wotB47XbMjO++Xgkdydh7K27SmtrG64eeuG89G561cP1Zceu3b22LnzqzI3FRSfaWi8e6X5Qf2V1o1Zu/Ly88HFaw6Xu+0vl9ve2NLoumW/2VRYXHHGai+vulRSUXe0rNZ8wnqkzHq0vLawtHo+h0uqKqsvV9XUglN/nD/+a/7ve3MzM9etXLE8I2O13d7w8P49V4vzzm236x9ni7Np/uJs/rvN3drmbm1uarzd5i62WMCOPXm7s7flZK5fszbjx/T0k+VlIYEb9XmZyQmW8bOMP8D4A6wxOXbyvxFDAjBbTu7dsjV7y+aMn7PSV64vOW0bHGGf9Y8M+rjBUb77tbf71cizgbHO3vd9QxNvvIF+D2vEG+gb8oOCCmvBb4X7cw/+tMti+/PBgGeyp3/kxdvRpy+HOp6/e9w98KRnsLPXM5fhrj5vV6+nq8/T8XK4s3cYpC1ZakpbmvbDMgBMNtuFTx+jcV1OxKeRFJbCghQWsBRWFUyn8HRElZFIZURlFNGUj7EoSE1NBQCkpKQAAGrr6hPJJM8LhMhQFIOhYDAUEgQhHIYKpQqlmBCEiYSQQlVKVZC6eLEhL1oEAKizXYjHYj6fj+d5RVFUVdU0jVKq63o8ZhCPxzVNi0Qiuqbpuv5dc421bmZmBmMsyzKEUJIkCEVK1dj09IcFotEPuq5H5/hOvtzgmJ2dna+akqcIIRhjRVGopk8Kop+HYQnxPEcICQaDoih+kU0mEwBge05uz/MXmh4lU4qialTVqKYrqkYUlYMkADFEchhKiMgSJhImX+Sv5UVFRclkEkKIMUYYI2RMjI0VJElCCImicRo/1iP/l81mM5p7Z8gLYIwFQfh3YoLj+fHxcZZl/X7/2NjYN3kek8nU3t6eSCRkWaYLEEIYhgmFQgzDBAIBjuMghA6H4zNcRTqQzdbcxwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4278fbfa2ae8645b4a6cc33db4eeb9ed/e4706/BlueIris5_INSTAR_MQTT_05.avif 230w", "/en/static/4278fbfa2ae8645b4a6cc33db4eeb9ed/d1af7/BlueIris5_INSTAR_MQTT_05.avif 460w", "/en/static/4278fbfa2ae8645b4a6cc33db4eeb9ed/7f308/BlueIris5_INSTAR_MQTT_05.avif 920w", "/en/static/4278fbfa2ae8645b4a6cc33db4eeb9ed/aebcf/BlueIris5_INSTAR_MQTT_05.avif 976w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4278fbfa2ae8645b4a6cc33db4eeb9ed/a0b58/BlueIris5_INSTAR_MQTT_05.webp 230w", "/en/static/4278fbfa2ae8645b4a6cc33db4eeb9ed/bc10c/BlueIris5_INSTAR_MQTT_05.webp 460w", "/en/static/4278fbfa2ae8645b4a6cc33db4eeb9ed/966d8/BlueIris5_INSTAR_MQTT_05.webp 920w", "/en/static/4278fbfa2ae8645b4a6cc33db4eeb9ed/a8e2b/BlueIris5_INSTAR_MQTT_05.webp 976w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4278fbfa2ae8645b4a6cc33db4eeb9ed/81c8e/BlueIris5_INSTAR_MQTT_05.png 230w", "/en/static/4278fbfa2ae8645b4a6cc33db4eeb9ed/08a84/BlueIris5_INSTAR_MQTT_05.png 460w", "/en/static/4278fbfa2ae8645b4a6cc33db4eeb9ed/c0255/BlueIris5_INSTAR_MQTT_05.png 920w", "/en/static/4278fbfa2ae8645b4a6cc33db4eeb9ed/798d4/BlueIris5_INSTAR_MQTT_05.png 976w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4278fbfa2ae8645b4a6cc33db4eeb9ed/c0255/BlueIris5_INSTAR_MQTT_05.png",
              "alt": "Blue Iris for your INSTAR IP Camera",
              "title": "Blue Iris for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now we can define another topic that is to updated once our camera falls back to idle. Click on `}<strong parentName="p">{`On reset...`}</strong>{` to continue:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/96da5b1079c1fcfbfedf64f203ddffeb/078fe/BlueIris5_INSTAR_MQTT_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "76.52173913043477%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAC1klEQVQoz32S20sUYRiHx3U9FOVtBXUTXRgmZBdBVCaKGVRmWUmUWFIqFWpZaplFtqXr2FomCR0gI+quv6KgEoowO6vjzvGbmZ3jNzuHb2a+cK2rrN/9w/M+8BKHm9sazvVX1Z7aX99ad+xMTe3xhpaO7thoz8DDczcStV3th65cqO++WlxWs626oaNv7Hzf/Uvxx5UHmw/WHSVuDfWPjAwnRu/dJMmBBBkbHGhsj3WSL9puPjl9fbyl9+nJy0+aesfPxp43XRs/0fOosedxfedYS9+zwQcvidPtXcf2VddVlhUXFa5ZvSovNzseJ+00BDxrQwvqusCzEBpKSuRYWlNTyHN0TVFS4tTnr0RF1d6q0u3lm0qKNhQXFhYWFBQMDJIYY05i30y/epd8PSP9hCZMpVImhAzLJmmaphnDMKi5JNHYevFAecXO8rKSrVXrNpYWrFgbJzMwz0xMvZ348nby20eeFyRRBEDkBQEAwDCM67pzSZqoOXm+Zv+RXeV7tlQ3bd7dvHL9jkGS9BDieV4URBFIpgE1TTMzsyzLtu10Oh2G4Sw1R+TmL8vKXZqdvyyatzRvyXKCIMjbCYwxAEDVVDklq6qqKIphGJqqybIsyZJhmr/hnGiU+LNIJDIPDyXCINA0zXEc27Yty3JcNwxDz/MQQhjjIAgwxjOzFBGN5mTALILIikSyM+ZhhHyO4wAAgiAoiuI4ThAEEEJoWb7v27aNMZ43R/8yD98ZwThUVdUwdF3XHcdZsEEIXdfFGPu+P29eFE7cuYsxliRRURSWYWampyVJcj0Ppi3DhLIsC4IAIfyPGVuW5SMky7I4P2Bn/D7yAAAsy5qmudC8OIyQ56KAFlWKBbximj8ocfJb6tN3xPIhxv9sXjgbeZ7jerysM4IMVNPiBI1K6hTtSjJaaF7UTA4lgiBQFEXXdZh5DNMwaJblBYHn+RmK4jiOppPvP3z8BakYOTM38JdcAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/96da5b1079c1fcfbfedf64f203ddffeb/e4706/BlueIris5_INSTAR_MQTT_06.avif 230w", "/en/static/96da5b1079c1fcfbfedf64f203ddffeb/d1af7/BlueIris5_INSTAR_MQTT_06.avif 460w", "/en/static/96da5b1079c1fcfbfedf64f203ddffeb/7f308/BlueIris5_INSTAR_MQTT_06.avif 920w", "/en/static/96da5b1079c1fcfbfedf64f203ddffeb/68819/BlueIris5_INSTAR_MQTT_06.avif 934w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/96da5b1079c1fcfbfedf64f203ddffeb/a0b58/BlueIris5_INSTAR_MQTT_06.webp 230w", "/en/static/96da5b1079c1fcfbfedf64f203ddffeb/bc10c/BlueIris5_INSTAR_MQTT_06.webp 460w", "/en/static/96da5b1079c1fcfbfedf64f203ddffeb/966d8/BlueIris5_INSTAR_MQTT_06.webp 920w", "/en/static/96da5b1079c1fcfbfedf64f203ddffeb/7d4d8/BlueIris5_INSTAR_MQTT_06.webp 934w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/96da5b1079c1fcfbfedf64f203ddffeb/81c8e/BlueIris5_INSTAR_MQTT_06.png 230w", "/en/static/96da5b1079c1fcfbfedf64f203ddffeb/08a84/BlueIris5_INSTAR_MQTT_06.png 460w", "/en/static/96da5b1079c1fcfbfedf64f203ddffeb/c0255/BlueIris5_INSTAR_MQTT_06.png 920w", "/en/static/96da5b1079c1fcfbfedf64f203ddffeb/078fe/BlueIris5_INSTAR_MQTT_06.png 934w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/96da5b1079c1fcfbfedf64f203ddffeb/c0255/BlueIris5_INSTAR_MQTT_06.png",
              "alt": "Blue Iris for your INSTAR IP Camera",
              "title": "Blue Iris for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Here I choose the same topic that I had before. But this time the payload will be `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`0`}</code>{` or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`OFF`}</code>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9f1ff0994f2f12e2b49dd9c4dc8131f8/e548f/BlueIris5_INSTAR_MQTT_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "73.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7DAAAOwwHHb6hkAAADIElEQVQoz12PXUwTWRiGD2CQaLKJFyReeEP2ZqPrGjEG3WwkqEGibqRJFUWtiCggFP9gQTBebGSyGleNlULB1hi9N16IP8QAioJG/rqykpnOtJ2/c86cmdOZthYITdi0oMZ98ub9vpsnXz7we3ml7UTrlu22klJHccn+wsI9p5x/MDc8f9952Hz9mv3SqYpr5ytbWvLytx443sDcunfVdf/y1a6CwhJbqQ1c/qvd7em52dnN3Oq46e5kbrgOn3Cea2VON/5Z5bxUUdvmqGl11LYeq2s7WttypKb5SE1zWVXj2YtXmlraQMOF5soD9kN7ijfn/5KXt2bd2p+Y9vaHD+53dbp9vruero5Ot8vn7fF0uTvuuLw93d6e7g7XbZ/3blV1NSjea9u9vWjnpo3rf16fm5tbU31SlkIfP/oFngvyXDCQ6sU9JASWEkw1VETgqD6zr6hox7bfNhQUrf4xv6K+bXic7RsaH54IjPiFZ68mnr2a6Hvjf9L/of/dp6Ex7vUo+3qUHRrjBt5/AmXOiwfLHPt2lW7afbLpimd4gn35dnJg5J/ng6O9/R8evRh53Peud3B8MU+/LE8GxnoHx0DOih9A9srslasAAE7n2bmZaCKCZhNxBGVVDqtyCCoi1THBMGZRghRDgwSrJiUzcQssX54NAMjKygIA1NU3JJNJIShgjKEKw2FRkuRwOCxJMqWURiKapiGMIUKGQQ1KQU5OTkpetgwAUO88Mzs74/f7eZ4nRKNpwzCMSIR+TpNIJEzTtCzLTPNFTl+uPV2XTCYx1gghEEGMMUIoEqHxeDwajcVi8WgsNSzLiqb5Tj7f2LSwsECIbpom0XWNEIw1Xdd1StmQ8i8viQoKCgKEMJx+aUnOzMwEABRs/bX36YuIaSFMNGIQnaZjYM0QJMSJSIaaKCkqxCrEioqW5K/Hy8vL5+fnFVXBGl4KXmwEoYoQUlUVIpj63LT+L9vtdklWVAgRQjAFWiQUCk1PTweDwampqUAgwLLs5OTkNxkAkJGRAQDweb1zc3OaphlpKKUIIZbjRFHkOI7neUEQZFlmGOY/5zI5cnPWK7kAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9f1ff0994f2f12e2b49dd9c4dc8131f8/e4706/BlueIris5_INSTAR_MQTT_07.avif 230w", "/en/static/9f1ff0994f2f12e2b49dd9c4dc8131f8/d1af7/BlueIris5_INSTAR_MQTT_07.avif 460w", "/en/static/9f1ff0994f2f12e2b49dd9c4dc8131f8/7f308/BlueIris5_INSTAR_MQTT_07.avif 920w", "/en/static/9f1ff0994f2f12e2b49dd9c4dc8131f8/6d24a/BlueIris5_INSTAR_MQTT_07.avif 975w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9f1ff0994f2f12e2b49dd9c4dc8131f8/a0b58/BlueIris5_INSTAR_MQTT_07.webp 230w", "/en/static/9f1ff0994f2f12e2b49dd9c4dc8131f8/bc10c/BlueIris5_INSTAR_MQTT_07.webp 460w", "/en/static/9f1ff0994f2f12e2b49dd9c4dc8131f8/966d8/BlueIris5_INSTAR_MQTT_07.webp 920w", "/en/static/9f1ff0994f2f12e2b49dd9c4dc8131f8/1e975/BlueIris5_INSTAR_MQTT_07.webp 975w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9f1ff0994f2f12e2b49dd9c4dc8131f8/81c8e/BlueIris5_INSTAR_MQTT_07.png 230w", "/en/static/9f1ff0994f2f12e2b49dd9c4dc8131f8/08a84/BlueIris5_INSTAR_MQTT_07.png 460w", "/en/static/9f1ff0994f2f12e2b49dd9c4dc8131f8/c0255/BlueIris5_INSTAR_MQTT_07.png 920w", "/en/static/9f1ff0994f2f12e2b49dd9c4dc8131f8/e548f/BlueIris5_INSTAR_MQTT_07.png 975w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9f1ff0994f2f12e2b49dd9c4dc8131f8/c0255/BlueIris5_INSTAR_MQTT_07.png",
              "alt": "Blue Iris for your INSTAR IP Camera",
              "title": "Blue Iris for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click on the `}<em parentName="p">{`Lightning`}</em>{` symbol to test-run your MQTT topic. You can use an `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/Android_Apps/"
      }}>{`MQTT debug tool`}</a>{` to see whether the topic is successfully updated. In my case I have an instance of `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/ioBroker/"
      }}>{`ioBroker`}</a>{` running to see the result of the test:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9d9d1d4ced6c6cd213735f627acd719a/98b29/BlueIris5_INSTAR_MQTT_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "76.52173913043477%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACeklEQVQoz2MIiEuNyW1y9U/wCU/3D03284+JS8tv6Jza3Du3rmdqVHVBdGNxelODpWeovV90Veu0mo6Zzf0LAmJyAkLCGerbGqdNnzJx+rT2vv6O/r7W/klh8dlJ2RWJOVURSYWhcYUhsYVBMXlhScVhScUBsbn+0dl+UVkhiYXFlfUMGXklCaFh4a5OehoqeoZGlW1Te2etXrTh8IK1+xesO7Bw/QEweXDBugPz1+wDCa49sGDt/kUbDnVPX8Hg7OHr4ejoamqioqzi4xvw4ePHV69evXv/4fOXL+/evX/9+g2EfPvu3efPXyDow6dPP37+PH/hEkN8VlGoh6eHg62GkZ17QMyHDx/evHr54d3rX9+/vn33/umTJ+/evXv+4vmrVy8/ffr4/v27r1+//geDy1euMQSll4RFJfm5+Jh6pYZn1P/79xck8w8E/qOCfzDwFyx16co1Bm5eQSY2bjZuAQYGJgMTC6i6//jA379/oZq5uLkYGBhYmJgZGBh09PRxWPsPIgxh/P0Ls5mTk5OBgYGZhQWs2RDuQqJshmpmhthsMHCaf/z8+fHjx88Q8PXr5z9/Pn/58uXz5+9fv377+uXrVxD+/v3H////L166iqLZwMjk85cvjx8/vn///v2HD+/duHFv244Hd+/cvnv//JUbl6/fun7z5rVrVx8+fPj+/buTp89ANTMxMTEwMCirqG3YuPn8xUvvP3x6/f7Dq4ePXy9f8/blq6ev3l28/fjGg+f3Hz6+d//BoydPHzx8dOzEKahmCGABh3lpWfn3Hz9fvnz15u27N1++gMg3bx4+fPjgwYNHjx7fun3n0eMnDx4+OnX6NAB4bmKlHRUUlgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9d9d1d4ced6c6cd213735f627acd719a/e4706/BlueIris5_INSTAR_MQTT_08.avif 230w", "/en/static/9d9d1d4ced6c6cd213735f627acd719a/d1af7/BlueIris5_INSTAR_MQTT_08.avif 460w", "/en/static/9d9d1d4ced6c6cd213735f627acd719a/7f308/BlueIris5_INSTAR_MQTT_08.avif 920w", "/en/static/9d9d1d4ced6c6cd213735f627acd719a/4d64a/BlueIris5_INSTAR_MQTT_08.avif 937w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9d9d1d4ced6c6cd213735f627acd719a/a0b58/BlueIris5_INSTAR_MQTT_08.webp 230w", "/en/static/9d9d1d4ced6c6cd213735f627acd719a/bc10c/BlueIris5_INSTAR_MQTT_08.webp 460w", "/en/static/9d9d1d4ced6c6cd213735f627acd719a/966d8/BlueIris5_INSTAR_MQTT_08.webp 920w", "/en/static/9d9d1d4ced6c6cd213735f627acd719a/ff430/BlueIris5_INSTAR_MQTT_08.webp 937w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9d9d1d4ced6c6cd213735f627acd719a/81c8e/BlueIris5_INSTAR_MQTT_08.png 230w", "/en/static/9d9d1d4ced6c6cd213735f627acd719a/08a84/BlueIris5_INSTAR_MQTT_08.png 460w", "/en/static/9d9d1d4ced6c6cd213735f627acd719a/c0255/BlueIris5_INSTAR_MQTT_08.png 920w", "/en/static/9d9d1d4ced6c6cd213735f627acd719a/98b29/BlueIris5_INSTAR_MQTT_08.png 937w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9d9d1d4ced6c6cd213735f627acd719a/c0255/BlueIris5_INSTAR_MQTT_08.png",
              "alt": "Blue Iris for your INSTAR IP Camera",
              "title": "Blue Iris for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now we are receiving an MQTT update, every time BlueIris triggers an alert - we can continue working with that in our smarthome solution, e.g. `}<a parentName="p" {...{
        "href": "/en/Software/Windows/Blue_Iris_v5/ioBroker/"
      }}>{`ioBroker`}</a>{`, `}<a parentName="p" {...{
        "href": "/en/Software/Windows/Blue_Iris_v5/Node-RED/"
      }}>{`Node-RED`}</a>{`, `}<a parentName="p" {...{
        "href": ""
      }}>{`OpenHAB`}</a>{` or `}<a parentName="p" {...{
        "href": ""
      }}>{`Home Assistant`}</a>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/aa8cee68f5acafbfc08f58b44da1ef04/5bf79/BlueIris5_INSTAR_MQTT_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "29.565217391304348%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABHElEQVQY02XEO0/DMBRA4fz/DRUQTGyoUIFYEAPQJ4g+oYlDH24dQ5qHE/teO07LjEgRC0efjhMmixggs9vUWB5DXu4yu90TRbm/KOzPTfWiFKiTXKYSHEpX8/5wNiZ8xqi38IeuP/Lex2Q28el07g+9NaHMX/0ilE7ny8wuBdIUHMH4ut1NR6/yzeVPL97tHev0xGgSdJ+D7tO62dKuWxBSEGIIsYSEg0EYfuaZUFI6Zze9Wv3h4Py+dtE8arRr9cfDy9bpde/kqnPcaPksLL52ylooS6ieG5PLXEqJqJ12f8WCDec8ihIAVAoUYCwU36R8I+I41YigFAL80VpDlRMlArXRxqA2gFjRQSxd+uGzKEqE1hr/paSUSn0Dmjg5i5B8b3kAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/aa8cee68f5acafbfc08f58b44da1ef04/e4706/BlueIris5_INSTAR_MQTT_09.avif 230w", "/en/static/aa8cee68f5acafbfc08f58b44da1ef04/d1af7/BlueIris5_INSTAR_MQTT_09.avif 460w", "/en/static/aa8cee68f5acafbfc08f58b44da1ef04/7f308/BlueIris5_INSTAR_MQTT_09.avif 920w", "/en/static/aa8cee68f5acafbfc08f58b44da1ef04/00c9f/BlueIris5_INSTAR_MQTT_09.avif 966w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/aa8cee68f5acafbfc08f58b44da1ef04/a0b58/BlueIris5_INSTAR_MQTT_09.webp 230w", "/en/static/aa8cee68f5acafbfc08f58b44da1ef04/bc10c/BlueIris5_INSTAR_MQTT_09.webp 460w", "/en/static/aa8cee68f5acafbfc08f58b44da1ef04/966d8/BlueIris5_INSTAR_MQTT_09.webp 920w", "/en/static/aa8cee68f5acafbfc08f58b44da1ef04/740ed/BlueIris5_INSTAR_MQTT_09.webp 966w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/aa8cee68f5acafbfc08f58b44da1ef04/81c8e/BlueIris5_INSTAR_MQTT_09.png 230w", "/en/static/aa8cee68f5acafbfc08f58b44da1ef04/08a84/BlueIris5_INSTAR_MQTT_09.png 460w", "/en/static/aa8cee68f5acafbfc08f58b44da1ef04/c0255/BlueIris5_INSTAR_MQTT_09.png 920w", "/en/static/aa8cee68f5acafbfc08f58b44da1ef04/5bf79/BlueIris5_INSTAR_MQTT_09.png 966w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/aa8cee68f5acafbfc08f58b44da1ef04/c0255/BlueIris5_INSTAR_MQTT_09.png",
              "alt": "Blue Iris for your INSTAR IP Camera",
              "title": "Blue Iris for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "controlling-blueiris-through-mqtt",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#controlling-blueiris-through-mqtt",
        "aria-label": "controlling blueiris through mqtt permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Controlling BlueIris through MQTT`}</h2>
    <p>{`We can also use MQTT to trigger events inside the BlueIris software. For example you can use a tool like `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/Windows_macOS_and_LINUX_Apps/#mqttfx"
      }}>{`MQTT.fx`}</a>{` to send the following payload to the MQTT topic `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`BlueIris/admin`}</code>{`:`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`camera=cameraname&trigger`}</code>{` (replace `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`cameraname`}</code>{` with the camera shortname you defined for your camera when you added it to BlueIris)`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/084aba77a94d380190d180a2f6123e1a/58fee/BlueIris5_INSTAR_MQTT_10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.78260869565218%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB6UlEQVQoz1XN3W7ScAAF8L6OxkFS+rECpQi0fHelm0KhDNqBUC46lIgIiyQgCmOj/zJZYmKMxAhtGZhsewON98Z4b2J8DwO6RH85OTl3B0rkpNxhj+YkLqPwu7k4l37aORmM33X1N/leXT5pljtd2L8jVduD87n+dlWsdSPs3n5elg6KUK3xuN9/2XrWrjVbR51Orqi0B+ft4etK/UUic+iLywSTCvAHvoSE0fcQH+8MpZJSRS6rT1ptKCtkGo06H49Q5LZt69bWndtfPn/6/u3rr58/jPl7pVQQknuVcjErChwbZWNhNhbx3/Xa7bZtHIdKJXV5sbpYLi1rMZ8bs9nMtKzV6uPl9fXl1ZW1WJiWZS0WhmkahvFhNjNNq9/vu1xuGIYhBxV2ByIul5PYcDoJHMdRJ4WRAcrr9fv9KIoiDgeCIJuBeDwuwg3bbHYcxyGYigalJhOOMgwdCNChUCgYDJIeCnP7EBSHNzDCjeE4hqLYDZIk18+ExytJsqIo1RuPHlblwoNM9TmfysZjUXaHSxfUTHZfEFKiKApCmmW5o1Yrl89D3O59TRsNj49H/9A0DZwOdR2MN8DoFAANAKCtS+/1etPpVBRFiE+mX52dAf0/YJ0xADr4Q/87NE2bTCaqqjIMQ9P0b5RS35ZW18sJAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/084aba77a94d380190d180a2f6123e1a/e4706/BlueIris5_INSTAR_MQTT_10.avif 230w", "/en/static/084aba77a94d380190d180a2f6123e1a/d1af7/BlueIris5_INSTAR_MQTT_10.avif 460w", "/en/static/084aba77a94d380190d180a2f6123e1a/7f308/BlueIris5_INSTAR_MQTT_10.avif 920w", "/en/static/084aba77a94d380190d180a2f6123e1a/cad33/BlueIris5_INSTAR_MQTT_10.avif 1051w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/084aba77a94d380190d180a2f6123e1a/a0b58/BlueIris5_INSTAR_MQTT_10.webp 230w", "/en/static/084aba77a94d380190d180a2f6123e1a/bc10c/BlueIris5_INSTAR_MQTT_10.webp 460w", "/en/static/084aba77a94d380190d180a2f6123e1a/966d8/BlueIris5_INSTAR_MQTT_10.webp 920w", "/en/static/084aba77a94d380190d180a2f6123e1a/42749/BlueIris5_INSTAR_MQTT_10.webp 1051w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/084aba77a94d380190d180a2f6123e1a/81c8e/BlueIris5_INSTAR_MQTT_10.png 230w", "/en/static/084aba77a94d380190d180a2f6123e1a/08a84/BlueIris5_INSTAR_MQTT_10.png 460w", "/en/static/084aba77a94d380190d180a2f6123e1a/c0255/BlueIris5_INSTAR_MQTT_10.png 920w", "/en/static/084aba77a94d380190d180a2f6123e1a/58fee/BlueIris5_INSTAR_MQTT_10.png 1051w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/084aba77a94d380190d180a2f6123e1a/c0255/BlueIris5_INSTAR_MQTT_10.png",
              "alt": "Blue Iris for your INSTAR IP Camera",
              "title": "Blue Iris for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`This will trigger an alarm for the selected camera in BlueIris and with it all activated alarm actions - e.g. alarm recording, etc.`}</p>
    <h3 {...{
      "id": "admin-commands",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#admin-commands",
        "aria-label": "admin commands permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Admin Commands`}</h3>
    <p>{`This is a list of the allowed admin command that you can use through MQTT:`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`camera=x&autocycle=1`}</code>{` or 0 Automates the auto-cycle function for camera x’s frame`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`camera=x&alerts=x`}</code>{` Enable or disable alerts on camera x (short name)`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`camera=x&enable=1`}</code>{` or 0 Enable or disable camera x (short name)`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`camera=x&escape`}</code>{` Equivalent to using Esc key on camera window to exit full screen or other temporary modes.`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`camera=x&flagalert=1`}</code>{` Mark the most recent alert as flagged in the clips database and timeline.`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`camera=x&flash=1`}</code>{` or 0 Enable or disable Flash broadcasting on camera x (short name). A camera reset will also be required (&reset)`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`camera=x&fullscreen=1`}</code>{` or 0`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`camera=x&hide=1`}</code>{` or 0 Hide or show camera x (short name)`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`camera=x&manrec=1`}</code>{` or 0 Start or stop manual recording on camera or group x (short name)`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`camera=x&mdelay=x`}</code>{` Delay motion detection on camera x (short name)`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`camera=x&motion=1`}</code>{` or 0 Enable or disable motion detection on camera x (short name)`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`camera=x&output=n`}</code>{`&msec=t Set or reset the digital output n (1-10) on camera x (short name). Use msec=0 to reset the output and any other value to set it.`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`camera=x&pause=n`}</code>{` Pause camera x (short name). n=-2,-1,0,1,2... for toggle, infinite, 0,+30s,+5min,+30m,+1h,+2h,+3h,+5h,+10h,+24h,+15m`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`camera=x&preset=n`}</code>{` Goto PTZ preset n on camera x (short name)`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`camera=x&priority=x`}</code>{` 1: temporarily move camera to top-left position, 0: return to normal position.`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`camera=x&profile=n`}</code>{` Force profile n on camera x (short name)`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`camera=x&ptz=n`}</code>{` PTZ command n on camera x (short name). n=0,1... for left,right,up,down,center,zoom+,zoom-`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`camera=x&ptzcycle=1`}</code>{` or 0 Enable or disable PTZ preset cycle on camera x`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`camera=x&reboot Reboot`}</code>{` camera x (short name) (as supported)`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`camera=x&reset Reset`}</code>{` camera x (short name)`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`camera=x&schedule=1`}</code>{` or 0 Enable or disable schedule on camera x (short name)`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`camera=x&select`}</code>{` Select camera x (short name). Omit the camera name (x is empty)
to de-select all cameras.`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`camera=x&snapshot`}</code>{` Snapshot on camera x (short name)`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`camera=x&trigger&memo=text`}</code>{` Trigger camera or group x (short name) as an External source; optionally add text to the memo field in the database.`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`camera=x&trigger=1`}</code>{` Trigger camera or group x (short name) as a Motion source`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`camera=x&trigger=-`}</code>{`1 Trigger camera (short name) as an ONVIF source`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`camera=x&trigger=0`}</code>{` Reset trigger on camera (short name). `}<strong parentName="li">{`NOTE`}</strong>{`: With the ONVIF source, the camera will remain triggered until the `}<em parentName="li">{`&trigger=0`}</em>{` command is used and the break time has expired.`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`camera=x&webcast=1`}</code>{` or 0 Enable or disable webcasting on camera x (short name)`}</li>
    </ul>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      